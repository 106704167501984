.whatsapp-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.whatsapp-icon-img {
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.whatsapp-icon-img:hover {
  transform: scale(1.1);
  /* Scale the icon to 110% of its original size on hover */
  transition: transform 0.2s ease-in-out;
  /* Apply a smooth transition effect */
}